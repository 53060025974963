import "./ManageProspectionRowComponent.css";
import axios from "axios";
import { useState } from "react";
import { ROOT_DOMAIN_API } from "../../../Constant";

function ManageProspectionRowComponent(props) {
  const [visibility, setVisibility] = useState(true);

  const handleDelete = function(){
    (async () => {
      try {
        const resp = await axios.post(ROOT_DOMAIN_API+"/manage/v1/pros_admin/prospection_removeProspectedDomain", {pros_id: props.prospection.site_pros_id});
        const obj = await resp.data;
        if(obj.results){
         setVisibility(false);
        }
      } catch (error) {
          console.error(error);
      }
    })();
  }

  return (
    <tr className={"ManageProspectionRow " + (visibility ? '' : 'Hide') +" " + props.childClass}>
        <td>{props.prospection.site_pros_date}</td>
        <td>{props.prospection.adm_name}</td>
        <td>{props.prospection.site_pros_com}</td>
        <td>{props.prospection.site_pros_url}</td>
        <td>
            <button className="ActionButton BRed TWhite" onClick={() => handleDelete()}>
                <i className="MediumIcon fa-solid fa-trash-can"></i>
            </button>
        </td>
    </tr>
  );
}

export default ManageProspectionRowComponent;
