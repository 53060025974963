import './ProsDetailsComponent.css'
import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AuthComponent from '../../components/AuthComponent/AuthComponent';
import SplashLoadComponent from '../../components/SplashLoadComponent/SplashLoadComponent';
import HeadLineComponent from '../../components/HeadLineComponent/HeadLineComponent';
import LateralNavComponent from '../../components/LateralNavComponent/LateralNavComponent';
import ProsDetailsHeadlineComponent from '../../components/ProsDetailsHeadlineComponent/ProsDetailsHeadlineComponent';
import ProsDetailsLemlistComponent from '../../components/ProsDetailsLemlistComponent/ProsDetailsLemlistComponent';
import ProsDetailsManualComponent from '../../components/ProsDetailsManualComponent/ProsDetailsManualComponent';
import ProsDetailsPhoneComponent from '../../components/ProsDetailsPhoneComponent/ProsDetailsPhoneComponent';
import ProsDetailsKarmaComponent from '../../components/ProsDetailsKarmaComponent/ProsDetailsKarmaComponent';
import { ROOT_DOMAIN_API } from '../../Constant';

function ProsDetailsComponent() {
  let { id } = useParams();
  const [splashLoad, setSplashLoad] = useState(true);
  const [siteProsUrl, setSiteProsUrl] = useState(null);
  const [salesItem, setSalesItem] = useState({adm_id: null, adm_name: null, adm_pros_avatar: null});

  useEffect(() => {
    if(!id){
      return;
    }

    (async () => {
      try {
          const resp = await axios(ROOT_DOMAIN_API+"/manage/v1/pros_user/prospectionSalesDetailsById?q="+id);
          const obj = await resp.data;
          if(obj.results!=null){
            setSalesItem(obj.results)
          }
      } catch (error) {
          console.error(error);
      }
      
    })();

    setSiteProsUrl(atob(id));
    setSplashLoad(true);
    setTimeout(() => {
      setSplashLoad(false);
    }, 3000)
  }, [id]);

  return (
    <div className="ProsDetails">
        <AuthComponent/>
        {splashLoad ? <SplashLoadComponent childClass="SplashLoadCard"/> : ''}
        <ToastContainer
            theme="colored"
            position="bottom-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            />
        <LateralNavComponent />
        <HeadLineComponent title={"Prospected domain details"} intro={salesItem.adm_name} avatar={salesItem.adm_pros_avatar} childClass="Headline"/>
        <ProsDetailsHeadlineComponent childClass="ProsDetailsHeadlineCard" site_pros_url={siteProsUrl}/>
        <ProsDetailsKarmaComponent siteProsUrl={siteProsUrl}/>
        <ProsDetailsLemlistComponent/>
        <ProsDetailsManualComponent/>
        <ProsDetailsPhoneComponent/>
    </div>
  );
}
  
export default ProsDetailsComponent;
  