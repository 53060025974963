import { useState } from "react";
import "./CaSalesComponent.css";
import 'react-day-picker/dist/style.css';
import { useEffect } from "react";
import axios from "axios";
import { ROOT_DOMAIN_API } from "../../Constant";
import { useNavigate} from "react-router-dom";

function CaSalesComponent(props) {

    const navigate = useNavigate();

    const [date, setDate] = useState(false);
    const [dateList, setDateList] = useState([]);
    const [data, setData] = useState([]);
    const [selectedData, setSelectedData] = useState([]);
    const [cacheDate, setCacheDate] = useState(false);

    useEffect(() => {
        let today = new Date();
        let tempDateList = [];
        
        for (let i = 0; i <= 6; i++) {
            let year = today.getFullYear();
            let month = today.getMonth() - i;
            let date = new Date(year, month, 1, 12);
            tempDateList.push(date.toISOString().split('T')[0]);
        }
        
        setDateList(tempDateList);
    }, []);

    useEffect(() => {
        setDate(dateList[0]);
    }, [dateList])

    useEffect(() => {
        loadData();
    }, [])

    const loadSalesDetails = function(salesId){
        navigate('/sales/'+salesId, { replace: false });
    }

    function loadData(){
        (async () => {
            try {
                const resp = await axios(ROOT_DOMAIN_API+"/manage/v1/pros_admin/prospection_caBySalesOverview");
                const obj = await resp.data;
                if(obj.status===true){
                    setData(obj.results);
                }
            } catch (error) {
                console.error(error);
            }
        })();
    }

    useEffect(() => {
        setSelectedData([]);
        setCacheDate(false);
        for (const [k, r] of Object.entries(data)) {
            if(k===date){
                let d = Object.keys(r).map((key) => r[key]);
                setSelectedData(d);
                setCacheDate(d[0]["cache_date"]);
            }
        }
    }, [data, date]);

    return (
        <div className={"CaSales " + props.childClass} >
            <div className='FlexRow JustifyBetween'>
                <div className='FlexRow'>
                    <h2 className="M0">CA By Sales</h2>
                    <h6 className='FlexRow AlignEnd ML15'>{cacheDate ? "Cache from: " + (new Date(cacheDate * 1000).toISOString().split("T")[0] + " " + new Date(cacheDate * 1000).toTimeString().split(" ")[0]) : ''}</h6>
                </div>
                <select className="BonusOverviewSelector" value={date} onChange={(e) => {setDate(e.target.value)}}>
                    {dateList.map((row, key) => {
                                return (<option value={row}>
                                        {row}
                                    </option>)
                            })}
                </select>
            </div>
            <table className="Width100">
                <thead className='TWhite BRed'>
                    <tr>
                        <th>Sales</th>
                        <th>CA Private €</th>
                        <th>Date</th>
                    </tr>
                </thead>
                <tbody>
                    {selectedData.map((row, key) => {
                        return (<tr>
                            <td>
                                <button className="ActionButton BPurple" onClick={() => loadSalesDetails(row.adm_id)}><i className="fa-solid fa-magnifying-glass"></i></button>
                                <span className='ML10'>{row.adm_name}</span>
                            </td>
                            <td>{row.ca_private}€</td>
                            <td>{row.date}</td>
                        </tr>)
                    })}
                    <tr></tr>
                    <tr></tr>
                    <tr></tr>
                </tbody>
            </table>
        </div>
    );
  }
    
  export default CaSalesComponent;
    