import { useState } from 'react';
import axios from 'axios';
import { useNavigate} from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import SplashLoadComponent from '../../components/SplashLoadComponent/SplashLoadComponent';
axios.defaults.withCredentials = true;

function LogoutComponent() {
    const navigate = useNavigate();
    
    if(window.location.href !== "https://www.themoneytizer.com/crm/login"&&!localStorage.getItem('crm_redirection')){
        localStorage.setItem('crm_redirection', true);
        window.location.href = "https://www.themoneytizer.com/crm/login";
    }

    const [splashLoad, setSplashLoad] = useState(true);

        
    const logout = function(){
        (async () => {
            const resp = await axios('https://www.themoneytizer.com/logout');
            const obj = await resp.data;
            if(obj){
                navigate('/login', { replace: false });
            }
        })();
        return false;
    }
    logout();

    setTimeout(() => {
        setSplashLoad(false);
    }, 3000)

    return (
    <div className="LogoutComponent">
        {splashLoad ? <SplashLoadComponent childClass="SplashLoadCard"/> : ''}
    </div>
    );
}

export default LogoutComponent;
