import './LastWebsitesComponent.css';
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import LastWebsitesRowComponent from '../LastWebsitesRowComponent/LastWebsitesRowComponent';
import { ROOT_DOMAIN_API } from '../../Constant';

function LastWebsitesComponent(props) {
    const [panelVisible, setPanelVisible] = useState(false);
    const [sites, setSites] = useState([]);

    useEffect(() => {
        (async () => {
            try {
              const resp = await axios(ROOT_DOMAIN_API+"/manage/v1/pros_user/prospection_lastProspectedWebsites");
              const obj = await resp.data;
              if(obj.results){
                setSites(obj.results);
              }
            } catch (error) {
                console.error(error);
            }
          })();
    }, [])

    const toggleVisibility = function(){
        if(panelVisible){
            setPanelVisible(false);
        } else {
            setPanelVisible(true);
        }
    }

    const handleSearchField = function(e){
        if(e.target.value.length>0){
            (async () => {
                try {
                  const resp = await axios.post("https://www.themoneytizer.com/pros_api/searchDomainInProspectedList", {domain: e.target.value});
                  const obj = await resp.data;
                  if(obj.results){
                    setSites(obj.results);
                  }
                } catch (error) {
                    console.error(error);
                }
              })();
        } else {
            (async () => {
                try {
                  const resp = await axios(ROOT_DOMAIN_API+"/manage/v1/pros_user/prospection_lastProspectedWebsites");
                  const obj = await resp.data;
                  if(obj.results){
                    setSites(obj.results);
                  }
                } catch (error) {
                    console.error(error);
                }
              })();
        }
    }

  return (
    <div className={"LastWebsites " + props.childClass}>
      <div className="FlexRow AlignCenter">
            <button onClick={() => toggleVisibility()} className="ActionButton BRed FullWidth FlexRow AlignCenter JustifyBetween">
                <i className="MediumIcon fa-solid fa-clock-rotate-left"></i>
                <h2 className="MT0 MB0 ML10">Your last websites prospected</h2>
                {panelVisible ? <i className="MediumIcon fa-solid fa-arrow-down"></i> : <i className="MediumIcon fa-solid fa-arrow-right"></i>}
            </button>
        </div>
        <div className={"LastWebsitesContainer MT10 " + (panelVisible ? "" : "Hide")}>
            <p className="MB0"><b>Search for a domain</b></p>
            <input type="text" className="Width95 MT10 MB20 SearchField" placeholder="plamov.fr" onChange={handleSearchField}/>
            <table className="FullWidth">
                <thead className="BRed TWhite">
                    <tr>
                        <th>Prospection date</th>
                        <th>Prospection type</th>
                        <th>Url</th>
                        <th>VU</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {sites.map((row, key) => {
                        return <LastWebsitesRowComponent
                            key={key}
                            feedback={row.site_pros_feedback}
                            site_pros_date={row.site_pros_date}
                            refreshWarmList={props.refreshWarmList}
                            site_pros_id={row.site_pros_id}
                            adm_name={row.adm_name}
                            site_pros_com={row.site_pros_com}
                            site_pros_url={row.site_pros_url}
                            site_pros_vu={row.site_pros_vu}/>
                    })}
                </tbody>
            </table>
        </div>
    </div>
  );
}

export default LastWebsitesComponent;
