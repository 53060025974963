import './LastProspectionSalesComponent.css'
import {useEffect, useState} from "react";
import axios from 'axios';
import LastProspectionSalesRowComponent from "../LastProspectionSalesRowComponent/LastProspectionSalesRowComponent";
import { ROOT_DOMAIN_API } from '../../Constant';

function LastProspectionSalesComponent(props) {
  const [listWebsite, setListWebsite] = useState([]);
  useEffect(() => {
    (async () => {
      if(!props.adm_id){
        return;
      }
      try {
        const resp = await axios(ROOT_DOMAIN_API+"/manage/v1/pros_user/prospection_lastProspectedWebsites",
          {params: {adm_id: props.adm_id}});
        const obj = await resp.data;
        if(obj.results){
          setListWebsite(obj.results);
        }
      } catch (error) {
          console.error(error);
      }
  })();
  }, [props.adm_id])
  return (
    <div className={"LastProspectionSales " + props.childClass}>
      <h2>Last Prospections</h2>
      <div className="MaxHeightTable">
        <table>
          <thead className="BRed TWhite">
              <tr>
                  <th>Type</th>
                  <th>Domain</th>
                  <th>Pros date</th>
                  <th>Action</th>
              </tr>
          </thead>
          <tbody>
            {listWebsite.map((row, key) => {
              return <LastProspectionSalesRowComponent 
              site_id={row.site_pros_site_id}
              pros_type={row.site_pros_com}
              pros_date={row.site_pros_date}
              pros_sub={row.site_pros_datetime}
              site_url={row.site_pros_url}/>
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
  
export default LastProspectionSalesComponent;
  