import { useEffect, useState } from 'react';
import './RealTimeCardComponent.css';
import axios from 'axios';
import RealTimeCardRowComponent from '../RealTimeCardRowComponent/RealTimeCardRowComponent';
import { ROOT_AVATAR_API, ROOT_DOMAIN_API } from '../../Constant';

function RealTimeSubscription(props) {

  const [list, setList] = useState([]);

  function subscriptionFetch(){
  (async () => {
      try {
          const resp = await axios(ROOT_DOMAIN_API+"/manage/v1/pros_user/prospection_realTimeSubscription");
          const obj = await resp.data;
          if(obj.results!=null){
          let parsedList = obj.results.map((el) => {
              let timestamp = (new Date(el.site_pros_datetime)).getTime();
              let delta = (new Date()) - timestamp;
              let seconds = Math.floor(delta/1000);
              let minutes = Math.floor(seconds/60);
              let hours = Math.floor(minutes/60);
              let days = Math.floor(hours/24);

              hours = hours-(days*24);
              minutes = minutes-(days*24*60)-(hours*60);
              seconds = seconds-(days*24*60*60)-(hours*60*60)-(minutes*60);

              let displayTime = seconds+" seconds ago";
              if(minutes>0){
                  displayTime = minutes+" minutes ago";
              }
              el.site_pros_datetime = displayTime;
              el.adm_pros_avatar = ROOT_AVATAR_API+(el.adm_pros_avatar_seed ?? "")+"&mouth=smile&clothing=graphicShirt&clothingGraphic=bear"

                  return el;
              })
              setList(parsedList)
              }
          } catch (error) {
              console.error(error);
          }
          
      })();
    }

  useEffect(() => {
        subscriptionFetch();
        setInterval(() => {subscriptionFetch()}, 60000);
  },[])
  return (
    <div className={"RealTimeCard " + props.childClass}>
        <h2>🔴 Live subscription</h2>
        {list.map((row, key) => {
          return <RealTimeCardRowComponent key={key} site_pros_url={row.site_pros_url} adm_name={row.adm_name} adm_pros_avatar={row.adm_pros_avatar} site_pros_datetime={row.site_pros_datetime}/>
        })}
        
    </div>
  );
}

export default RealTimeSubscription;
