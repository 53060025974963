import './SalesStatisticRowComponent.css';
import { useNavigate} from "react-router-dom";

function SalesStatisticRowComponent(props) {
  const navigate = useNavigate();

  const loadSalesDetails = function(salesId){
    navigate('/sales/'+salesId, { replace: false });
  }
  return (
    <tr className={"SalesStatisticRow " + props.childClass}>
        <td className="FlexRow JustifyBetween">{props.adm_name}<button className="ActionButton BPurple" onClick={() => loadSalesDetails(props.adm_id)}><i className="fa-solid fa-magnifying-glass"></i></button></td>
        <td>{props.consolidated}</td>
        <td>{props.activated}</td>
        <td>{props.sub_total} | {props.sub_total_child}</td>
        <td>{props.pros_total}</td>
        <td>{props.sub_manual} | {props.sub_manual_child}</td>
        <td>{props.sub_lemlist} | {props.sub_lemlist_child}</td>
        <td>{props.sub_phone} | {props.sub_phone_child}</td>
        <td>{props.pros_manual}</td>
        <td>{props.pros_lemlist}</td>
        <td>{props.pros_phone}</td>
        <td>{props.sub_lite}</td>
        <td>{props.dunning}</td>
    </tr>
  );
}

export default SalesStatisticRowComponent;
