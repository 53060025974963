import './LemlistImportComponent.css';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { ROOT_DOMAIN_API } from '../../Constant';

function LemlistImportComponent(props) {

    const [salesList, setSalesList] = useState([]);
    const [panelVisible, setPanelVisible] = useState(false);
    const [listWebsite, setListWebsite] = useState("");
    const [admId, setAdmId] = useState(0);

    const toggleVisibility = function(){
        if(panelVisible){
            setPanelVisible(false);
        } else {
            setPanelVisible(true);
        }
    }

    const handleParseAndUpload = function(){
        if(admId===0){
            return;
        }
        (async () => {
            try {
                const resp = await axios.post(ROOT_DOMAIN_API+"/manage/v1/pros_user/prospection_addLemlistImport", {payload: listWebsite, adm_id: admId});
                const obj = await resp.data;
                if(obj.status&&obj.results!==0){
                    if(obj.output){
                        const blob = new Blob([obj.output], { type: 'text/csv;charset=utf-8;' });
                        const link = document.createElement('a');
                        const url = URL.createObjectURL(blob);
                        link.setAttribute('href', url);
                        link.setAttribute('download', 'data.csv');
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    }
                    toast.success('🎉 '+obj.results+' sites have been successfully imported.', {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        });
                } else if(obj.status) {
                    toast.error('🐧 The sites in the list have already been imported.', {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        });
                } else {
                    toast.error('🚨 An error has occurred, refresh the page and try again.', {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        });
                }
            } catch (error) {
                console.error(error);
            }
        })()
    }

    const handleWebsitesListChange = function(e){
        setListWebsite(e.target.value);
    }

    const handleProsChange = function(e){
        setAdmId(e.target.value);
    }
    
    useEffect(() => {
        (async () => {
            try {
                const resp = await axios(ROOT_DOMAIN_API+"/manage/v1/pros_user/prospection_salesList");
                const obj = await resp.data;
                if(obj.results!=null){
                    setSalesList(obj.results)
                }
            } catch (error) {
                console.error(error);
            }
            
        })()
    }, [])

  return (
    <div className={"LemlistImport " + props.childClass}>
        <div className="FlexRow AlignCenter">
            <button onClick={() => toggleVisibility()} className="ActionButton BRed FullWidth FlexRow AlignCenter JustifyBetween">
                <i className="MediumIcon fa-solid fa-file-lines"></i>
                <h2 className="MT0 MB0 ML10">Import lemlist</h2>
                {panelVisible ? <i className="MediumIcon fa-solid fa-arrow-down"></i> : <i className="MediumIcon fa-solid fa-arrow-right"></i>}
            </button>
        </div>
        <div className={"LemlistImportContainer MT10 " + (panelVisible ? "" : "Hide")}>
            <div className="FlexCol FirstCol">
                <select className="SalesSelect P5 MB15" onChange={handleProsChange}>
                    <option value={0}>Select a prospector</option>
                    {salesList.map((row, key) => {
                        return <option key={key} value={row.adm_id}>{row.adm_name}</option>
                    })}
                </select>
                <textarea className="TextAreaImport" onChange={handleWebsitesListChange}></textarea>
            </div>
            <div className="FlexCol SecondCol">
                <p className="ImportDescription MT0">
                    This tool allows you to import all the sites you have prospected via lemlist.<br/>To do this you need to copy and paste the list of your sites directly into the field below.
                    Be careful to import only the urls of the sites and only one url per line.
                </p>
                <button className="ImportValidation ActionButton BRed" onClick={handleParseAndUpload}>Parse and upload</button>
            </div>
        </div>
    </div>
  );
}

export default LemlistImportComponent;
