import LastConfirmedWebsitesRowComponent from "../LastConfirmedWebsitesRowComponent/LastConfirmedWebsitesRowComponent";
import { useState } from "react";
import "./LastConfirmedWebsitesComponent.css";
import 'react-day-picker/dist/style.css';
import { useEffect } from "react";
import axios from "axios";
import { ROOT_DOMAIN_API } from "../../Constant";

function LastConfirmedWebsitesComponent(props) {
    const [confirmedList, setConfirmedList] = useState([]);
    useEffect(() => {
        if(!props.adm_id){
            return;
        }
        (async () => {
            try {
              const resp = await axios(ROOT_DOMAIN_API+"/manage/v1/pros_user/prospection_lastConfirmedWebsites", {user_id: props.adm_id});
              const obj = await resp.data;
              if(obj.results){
                setConfirmedList(obj.results);
              }
            } catch (error) {
                console.error(error);
            }
        })();
    }, [props.userId, props.adm_id])

    return (
        <div className={"LastConfirmedWebsites " + props.childClass} >
            <h2 className="MT0 MB10 ML10">Last Confirmed Websites</h2>
            <div className="LastConfirmedWebsitesGrid">
                {
                    confirmedList.map((row, key) => {
                        return <LastConfirmedWebsitesRowComponent
                        key={key}
                        childClass={"CaSalesRowCard"}
                        mail={row.site_pros_contact_mail}
                        url={row.site_pros_url}
                        pros_com={row.site_pros_com}
                        tags={row.tags}
                        site_id={row.site_pros_site_id}
                        abstract={row.site_pros_abstract}/>
                    })
                }
            </div>
        </div>
    );
  }
    
  export default LastConfirmedWebsitesComponent;
    