import './SalesStatisticComponent.css';
import SalesStatisticRowComponent from '../SalesStatisticRowComponent/SalesStatisticRowComponent';
import { useState, useEffect } from 'react';
import axios from 'axios';
import BarLoader from "react-spinners/BarLoader";
import { ROOT_DOMAIN_API } from '../../Constant';

function SalesStatisticComponent(props) {
    const [listSite, setListSite] = useState([]);
    const [tSubscribedC, setTSubscribedC] = useState(0);
    const [tSubscribedP, setTSubscribedP] = useState(0);
    const [tProspected, setTProspected] = useState(0);
    const [tProspectedManual, setTProspectedManual] = useState(0);
    const [tProspectedLemlist, setTProspectedLemlist] = useState(0);
    const [tSubscribedManualC, setTSubscribedManualC] = useState(0);
    const [tSubscribedManualP, setTSubscribedManualP] = useState(0);
    const [tSubscribedLemlistC, setTSubscribedLemlistC] = useState(0);
    const [tSubscribedLemlistP, setTSubscribedLemlistP] = useState(0);
    const [tSubscribedLite, setTSubscribedLite] = useState(0);
    const [tConsolidated, setTConsolidated] = useState(0);
    const [tActivated, setTActivated] = useState(0);
    const [tDunning, setTDunning] = useState(0);
    const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split("T")[0]);
    const [rawJson, setRawJson] = useState([]);
    const [jsonPayload, setJsonPayload] = useState([]);
    const [cacheDate, setCacheDate] = useState(false);
    const [displayDateList, setDisplayDateList] = useState([]);

    const {updateGlobalStats} = props;

    useEffect(() => {
        if(!rawJson||rawJson.length===0){
            return;
        }
        
        let r = rawJson.filter((row) => {
            
            console.log(selectedDate.slice(0, -3));
            console.log(row.date.split(" ")[0].slice(0, -3));
            return row.date.split(" ")[0].slice(0, -3) === selectedDate.slice(0, -3)
        })
        if(r.length>0){
            setJsonPayload(r[0].payload);
        }
    }, [rawJson, selectedDate])

    useEffect(() => {
        let total = {
            sub_p: 0,
            sub_c: 0,
            sub_l: 0,
            sub_manual_p: 0,
            sub_manual_c: 0,
            sub_lemlist_p: 0,
            sub_lemlist_c: 0,
            pros: 0,
            pros_lemlist: 0,
            pros_manual: 0,
            consolidated: 0,
            dunning: 0,
            activated: 0,
        }

        jsonPayload.forEach((row) => {
            total.sub_p         += parseInt(row.subscription_total);
            total.sub_c         += parseInt(row.subscription_total_child);
            total.sub_l         += parseInt(row.subscription_lite);
            total.sub_manual_c  += parseInt(row.subscription_manual_child);
            total.sub_manual_p  += parseInt(row.subscription_manual);
            total.sub_lemlist_p += parseInt(row.subscription_lemlist);
            total.sub_lemlist_c += parseInt(row.subscription_lemlist_child);
            total.pros          += parseInt(row.prospected_total);
            total.pros_manual   += parseInt(row.prospected_manual);
            total.pros_lemlist  += parseInt(row.prospected_lemlist);
            total.consolidated  += parseInt(row.consolidated);
            total.dunning       += parseInt(row.dunning);
            total.activated     += parseInt(row.activated);
        });

        setListSite(jsonPayload);
        setTSubscribedP(total.sub_p);
        setTSubscribedC(total.sub_c);
        setTSubscribedManualP(total.sub_manual_p);
        setTSubscribedLemlistP(total.sub_lemlist_p);
        setTSubscribedLemlistC(total.sub_lemlist_c);
        setTProspectedManual(total.pros_manual);
        setTProspectedLemlist(total.pros_lemlist);
        setTSubscribedLite(total.sub_l);
        setTDunning(total.dunning);
        setTConsolidated(total.consolidated);
        setTActivated(total.activated);
        setTProspected(total.pros);
        setTSubscribedManualC(total.sub_manual_c);

        updateGlobalStats("tSubscribed", total.sub_p);
        updateGlobalStats("tProspectedManual", total.pros_manual);
        updateGlobalStats("tProspectedLemlist", total.pros_lemlist);
        updateGlobalStats("tProspected", total.pros);
        // eslint-disable-next-line
    }, [jsonPayload]);

    const fetchProspectionList = async function(){
        try {
            const resp = await axios.post(ROOT_DOMAIN_API+"/manage/v1/pros_user/prospection_statisticsList");
            const obj = await resp.data;
            setRawJson(obj.results);
            setCacheDate(obj.results[0].cache_date)
            console.log(obj.results[0].cache_date)
            let tmpDateList = [];
            obj.results.forEach((r,k) => {
                if(k!==0){
                    let t = r.date.split("-");
                    let tString = t[0]+"-"+t[1]+"-01";
                    tmpDateList.push(tString);
                }
            })
            setDisplayDateList(tmpDateList)

        } catch (error) {
            console.error(error);
        }
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        setListSite([]);
        fetchProspectionList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleSelectedDate = function(val){
        setSelectedDate(val);
    }

  return (
    <div className={"SalesStatistic " + props.childClass}>
        <div className='FlexRow JustifyBetween'>
            <div className='FlexRow'>
                <h2>Sales statistics</h2>
                <h6 className='FlexRow AlignEnd ML15'>{cacheDate ? "Cache from: " + (new Date(cacheDate * 1000).toISOString().split("T")[0] + " " + new Date(cacheDate * 1000).toTimeString().split(" ")[0]) : ''}</h6>
            </div>
            <select className="InputFeedback MR10" onChange={(event) => {handleSelectedDate(event.target.value)}}>
                {displayDateList.map((row, key) => {
                    return <option value={row}>{(row.split('-')[0]+"-"+row.split('-')[1])}</option>
                })}
            </select>
        </div>
        <div className={listSite.length > 0 ? 'Hide' : 'FlexRow AlignCenter JustifyCenter'}>
            <BarLoader className="MT20" color="#ababab" height="5px" width="75%" loading={true}/>
        </div>
        <table className={listSite.length > 0 ? '' : 'Hide'}>
            <thead className="BRed TWhite">
                <tr>
                    <th>Sales</th>
                    <th>
                        Confirmed<br/>
                        Website<br/>
                    </th>
                    <th>
                        Activation
                    </th>
                    <th>
                        Subscription<br/>
                        Total | Child<br/>
                    </th>
                    <th>
                        Prospected<br/>
                        Website<br/>
                    </th>
                    <th>
                        Subscribed<br/>
                        Manually<br/>
                    </th>
                    <th>
                        Subscribed<br/>
                        Lemlist<br/>
                    </th>
                    <th>
                        Subscribed<br/>
                        Phone<br/>
                    </th>
                    <th>
                        Prospected<br/>
                        Manually<br/>
                    </th>
                    <th>
                        Prospected<br/>
                        Lemlist<br/>
                    </th>
                    <th>
                        Prospected<br/>
                        Phone<br/>
                    </th>
                    <th>
                        Subscribed<br/>
                        Lite<br/>
                    </th>
                    <th>
                        Dunning<br/>
                        Counter<br/>
                    </th>
                </tr>
            </thead>
            <tbody>
                {listSite.map((row, key) => {
                    return <SalesStatisticRowComponent
                    key={key}
                    childClass={"SalesStatisticRowCard"}
                    activated={row.activated}
                    consolidated={row.consolidated}
                    adm_id={row.adm_id}
                    adm_name={row.adm_name}
                    sub_total={row.subscription_total}
                    sub_total_child={row.subscription_total_child}
                    pros_total={row.prospected_total}
                    sub_manual={row.subscription_manual}
                    sub_manual_child={row.subscription_manual_child}
                    sub_lemlist={row.subscription_lemlist}
                    sub_lemlist_child={row.subscription_lemlist_child}
                    sub_phone_child={row.subscription_phone_child}
                    pros_lemlist={row.prospected_lemlist}
                    pros_manual={row.prospected_manual}
                    sub_lite={row.subscription_lite}
                    dunning={row.dunning}
                    pros_phone={row.prospected_phone}
                    sub_phone={row.subscription_phone}
                    />
                })}
                <tr className="BRed TWhite TCenter">
                    <td>Total</td>
                    <td>{tConsolidated}</td>
                    <td>{tActivated}</td>
                    <td>{tSubscribedP} | {tSubscribedC}</td>
                    <td>{tProspected}</td>
                    <td>{tSubscribedManualP} | {tSubscribedManualC}</td>
                    <td>{tSubscribedLemlistP} | {tSubscribedLemlistC}</td>
                    <td>0</td>
                    <td>{tProspectedManual}</td>
                    <td>{tProspectedLemlist}</td>
                    <td>0</td>
                    <td>{tSubscribedLite}</td>
                    <td>{tDunning}</td>
                </tr>
            </tbody>
        </table>
    </div>
  );
}

export default SalesStatisticComponent;
