import './LateralNavComponent.css';
import {Link} from "react-router-dom";
import { useState } from 'react';

function LateralNavComponent(props) {
  const [toggleLateralNavBar, setToggleLateralNavBar] = useState(false);
  const handleToggleLateralNavBar = function(){
    if(toggleLateralNavBar){
      setToggleLateralNavBar(false);
      return;
    }
    setToggleLateralNavBar(true);
  }

  return (
    <div>
      <nav className={"lateralNavbar " + (toggleLateralNavBar ? "" : "Hide")}>
          <div className="CloseContainer">
            <button onClick={handleToggleLateralNavBar}><i className="fa-solid fa-xmark"></i></button>
          </div>
          <ul>
              <li className={props.pageTab === "overview" ? "Highlight" : ""}>
                <Link to="/"><i className="fa-solid fa-grip"></i> Overview</Link>
              </li>
              <li className={props.pageTab === "admin" ? "Highlight" : ""}>
                <Link to="/admin"><i className="fa-solid fa-lock"></i> Admin</Link>
              </li>
              <li className={props.pageTab === "logout" ? "Highlight" : ""}>
                <Link to="/logout"><i className="fa-solid fa-arrow-right-from-bracket"></i> Logout</Link>
              </li>
          </ul>
      </nav>
      <button onClick={handleToggleLateralNavBar} className={"BurgerMenu " + (toggleLateralNavBar ? "Hide" : "")}><i className="fa-solid fa-bars"></i></button>
    </div>
  );
}

export default LateralNavComponent;
