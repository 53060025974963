import './GraphSalesSubscribedComponent.css'
import { useEffect } from 'react';
import axios from 'axios';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, LinearScale, CategoryScale, PointElement, LineElement, Filler } from 'chart.js';
import { Line } from 'react-chartjs-2';
import { useState } from 'react';
import { ROOT_DOMAIN_API } from '../../Constant';

ChartJS.register(ArcElement, Tooltip, Legend, LinearScale, CategoryScale, PointElement, LineElement, Filler);

function GraphSalesSubscribedComponent(props) {
  const [graphData, setGraphData] = useState(null);
  useEffect(() => {
    if(!props.adm_id){
        return;
    }
    (async () => {
      try {
        const resp = await axios.post(ROOT_DOMAIN_API+"/manage/v1/pros_user/prospection_subscribedGraphics", {adm_id: props.adm_id});
        const obj = await resp.data;
        var dateList = [];
        var amountList = [];

        if(obj.results!=null){
          obj.results.forEach((row, key) => {
            dateList.push(row.pros_date);
            amountList.push(row.total);
          })

          const labels = dateList;
          var data = {
              labels,
              datasets: [
                  {
                      label: 'Subscribed website',
                      lineTension: 0.8,
                      data: amountList,
                      borderColor: ['rgba(39, 174, 96,1.0)'],
                      backgroundColor: ['rgba(39, 174, 96,0.4)'],
                      fill: true,
                      yAxisID: 'y',
                  },
              ],
            };

          setGraphData(data);
        }
      } catch (error) {
          console.error(error);
      }
    })();
  }, [props.adm_id]);

    

  return (
    <div className={"GraphSalesSubscribed " + props.childClass}>
        <h2 className="M0">{props.title}</h2>
        {graphData !== null ? <Line data={graphData} /> : ''}
    </div>
  );
}
  
export default GraphSalesSubscribedComponent;