import "./ImportAdminProspectionComponent.css";
import axios from "axios";
import { useState, useEffect } from "react";
import { toast } from 'react-toastify';
import { ROOT_DOMAIN_API } from "../../Constant";

function ImportAdminProspectionComponent(props) {
  const [salesList, setSalesList] = useState([]);
  const [textAreaProsSub, setTextAreaProsSub] = useState("")
  const [salesProsSub, setSalesProsSub] = useState("1");
  const [typeProsSub, setTypeProsSub] = useState("lemlist");
  const [textAreaPros, setTextAreaPros] = useState("")
  const [salesPros, setSalesPros] = useState("1");
  const [typePros, setTypePros] = useState("lemlist");

  useEffect(() => {
    (async () => {
        try {
          const resp = await axios(ROOT_DOMAIN_API+"/manage/v1/pros_user/prospection_salesList");
          const obj = await resp.data;
          if(obj.results){
            setSalesList(obj.results);
          }
        } catch (error) {
            console.error(error);
        }
      })();
  }, []);

  const handleImportProsSub = function(){
    (async () => {
      try {
        const resp = await axios.post(ROOT_DOMAIN_API+"/manage/v1/pros_admin/prospection_bulkImport", {payload: textAreaProsSub, adm_id: salesProsSub, pros_type: typeProsSub, pros_import: "pros_sub"});
        const obj = await resp.data;
        if(obj.results){
          if(obj.status&&obj.results!==0){
            toast.success('🎉 '+obj.results+' sites have been successfully imported.', {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
        } else if(obj.status) {
            toast.error('🐧 The sites in the list have already been imported.', {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
        } else {
            toast.error('🚨 An error has occurred, refresh the page and try again.', {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
        }
        }
      } catch (error) {
          console.error(error);
      }
    })();
  }

  const handleImportPros = function(){
    (async () => {
      try {
        const resp = await axios.post(ROOT_DOMAIN_API+"/manage/v1/pros_admin/prospection_bulkImport", {payload: textAreaPros, adm_id: salesPros, pros_type: typePros, pros_import: "pros"});
        const obj = await resp.data;
        if(obj.results){
          if(obj.status&&obj.results!==0){
            toast.success('🎉 '+obj.results+' sites have been successfully imported.', {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
        } else if(obj.status) {
            toast.error('🐧 The sites in the list have already been imported.', {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
        } else {
            toast.error('🚨 An error has occurred, refresh the page and try again.', {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
        }
        }
      } catch (error) {
          console.error(error);
      }
    })();
  }

  const handleTextAreaPros = function(evt){
    setTextAreaPros(evt.target.value);
  }

  const handleSalesPros = function(evt){
    setSalesPros(evt.target.value);
  }

  const handleTypePros = function(evt){
    setTypePros(evt.target.value);
  }

  const handleTextAreaProsSub = function(evt){
    setTextAreaProsSub(evt.target.value);
  }

  const handleSalesProsSub = function(evt){
    setSalesProsSub(evt.target.value);
  }

  const handleTypeProsSub = function(evt){
    setTypeProsSub(evt.target.value);
  }

  return (
    <div className={"ImportAdminProspection " + props.childClass}>
        <h1>Import prospection</h1>
        <div className="FlexCol">
            <p><b>Import website already subscribed</b></p>
            <div className="FlexRow JustifyAround">
                <div className="FlexCol Width100">
                  <p className="MT0">Only site id separated with line break</p>
                  <textarea onChange={handleTextAreaProsSub} className="ImportTextArea" placeholder="101&#10;102&#10;103&#10;104&#10;105"></textarea>
                </div>
                <div className="FlexCol ML20 Width20 JustifyBetween">
                    <label for="prosSubType">Prospection type</label>
                    <select onChange={handleTypeProsSub} id="prosSubType">
                        <option value="lemlist">Lemlist</option>
                        <option value="phone">Phone calling</option>
                        <option value="formulaire">Manual</option>
                    </select>
                    <label className="MT20" for="prosSubType">Apply on</label>
                    <select onChange={handleSalesProsSub} id="prosSubSales">
                        {salesList.map((row, key) => {
                          return <option key={key} value={row.adm_id}>{row.adm_name}</option>
                        })}
                    </select>
                    <button onClick={handleImportProsSub} className="ActionButton ValidationButton MT10">Import list</button>
                </div>
            </div>
            <p className="MT20"><b>Import website not yet subscribed</b></p>
            <div className="FlexRow JustifyAround">
                <div className="FlexCol Width100">
                  <p className="MT0">Only url separated with line break</p>
                  <textarea onChange={handleTextAreaPros} className="ImportTextArea" placeholder="https://website1.com&#10;https://website2.com&#10;https://website3.com&#10;https://website4.com&#10;https://website5.com"></textarea>
                </div>
                <div className="FlexCol ML20 Width20 JustifyBetween">
                    <label for="prosSubType">Prospection type</label>
                    <select onChange={handleTypePros} id="prosSubType">
                        <option value="lemlist">Lemlist</option>
                        <option value="phone">Phone calling</option>
                        <option value="formulaire">Manual</option>
                    </select>
                    <label className="MT20" for="prosSubType">Apply on</label>
                    <select onChange={handleSalesPros} id="prosSubSales">
                        {salesList.map((row, key) => {
                          return <option key={key} value={row.adm_id}>{row.adm_name}</option>
                        })}
                    </select>
                    <button onClick={handleImportPros} className="ActionButton ValidationButton MT10">Import list</button>
                </div>
            </div>
        </div>
    </div>
  );
}

export default ImportAdminProspectionComponent;
